@import '../layout.scss';

body{
  height: 100%;
  padding: 5rem;
}

main {
  display: flex;
  margin: 0 auto;
  
  .container {
    position: relative;
    width: 100%;

    .header {
      height: 100px;
      margin-bottom: 3rem;
      
      .print {
        float: right;
      }

      > * {
        float: left;
      }
    }

    .controls {
      position: relative;
      margin-top: 1rem;
      margin-bottom: 2rem;
      height: 50px;

      .refresh {
        float: right;

        .refresh__timestamp {
          margin-top: .7rem;
        } 

        .refresh__button {
          margin-left: 1rem;
        }
      }

      .filter__date {
        width: 250px;

        .filter__date--text {
          padding-top: 13px;
          margin-right: 1rem;
        }

        .filter__date--picker {
          * {
            cursor: pointer;
          }
        }

        > * {
          float: left;
        }
      }

      .filter__closed {
        .MuiIconButton-colorSecondary:hover {
          background-color: unset;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
          color: unset;
        }

        .filter__closed--text {
          padding-top: 13px;
        }
      }

      .filter__closed, .refresh {
        margin-top: 1rem;
      }

      * {
        float: left;
      }
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    h3 {
      margin-bottom: 1rem;
    }

    .delivery-data {
      height: 70vh;
      width: 90vw;
      overflow-y: scroll;
      th {
        font-size: 1.1rem;
        font-weight: bold;
      }
      td {
        font-size: .9rem;
      }

      .MuiTableCell-body span {
        display: block;
      }
    }
  }
}

.MuiDialog-root {
  .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected {
    background-color: rgb(0, 76, 105);
  }
  .MuiButton-textPrimary {
    color: rgb(0, 76, 105);
  }
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }

  .delivery-data {
    overflow: visible;
    overflow-y: unset;
    height: unset !important;
  }

  .controls, .print {
    display: none;
  }
}